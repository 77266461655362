import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Detail = _resolveComponent("Detail")

  return (_ctx.setting.visible)
    ? (_openBlock(), _createBlock(_component_Detail, {
        key: 0,
        setting: _ctx.setting
      }, null, 8, ["setting"]))
    : _createCommentVNode("", true)
}